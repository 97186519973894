const sortByString = (sortOrder=1, field='name') => {
  // sortOrder: 1 for high to low, or -1 for low to high
  return (a,b) => {
    const el1 = a[field]?.toUpperCase() || '';
    const el2 = b[field]?.toUpperCase() || '';

    return sortOrder * (el2 < el1 ? 1 : -1);
  }
}

export default sortByString;