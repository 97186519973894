const actionTypes = {
  SET_USER: 'SET_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  UPDATE_SERIES_LIST: 'UPDATE_SERIES_LIST',
  UPDATE_SERIES_ENTRIES: 'UPDATE_SERIES_ENTRIES',
  UPDATE_SERIES_DETAIL: 'UPDATE_SERIES_DETAIL',
  FETCHING: 'FETCHING',
  UPDATE_BOOK_DETAIL: 'UPDATE_BOOK_DETAIL',
  UPDATE_USER_LEVELS: 'UPDATE_USER_LEVELS',
  UPDATE_ENTRY_DATA: 'UPDATE_ENTRY_DATA'
}

export default actionTypes;